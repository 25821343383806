<template>
  <section id="hero">
    <v-sheet
      dark
      tile
    >
      <v-img
        :src="require('@/assets/Main/landscaping/gold-coast/gold (1).webp')"
        :height="$vuetify.breakpoint.xsOnly ? '100vh' : '100vh'"
        :gradient="gradient"
      >
        <template v-slot:placeholder>
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            />
          </v-row>
        </template>
        <v-container
          light
          fill-height
          fluid
          :px-12="$vuetify.breakpoint.mdAndUp"
        >
          <v-row
            align="center"
            justify="start"
          >
            <v-slide-x-reverse-transition appear>
              <v-col
                cols="12"
                md="7"
                ma="0"
              >
                <div>
                  <h1
                    :class="[
                      $vuetify.breakpoint.mdAndUp ? 'display-3' : 'headline'
                    ]"
                    class="font-weight-bold"
                  >
                    Landscaping/Rehabilitation Services
                  </h1>

                  <v-divider class="mb-3" />

                  <div class="subheading mb-4">
                    <p>
                      <em>Beautiful, Indigenous, Modern.. </em>
                    </p>
                  </div>
                  <v-row>
                    <!-- <v-btn
                      color="thirdley"
                      class="ml-3"
                      tile
                      outlined
                      @click="ShowMenuClicked = true"
                    >
                      Menu&nbsp;&nbsp;
                      <span style="font-size: 16px;">
                        <font-awesome-icon right :icon="['fas', 'book-open']" />
                      </span>
                    </v-btn> -->
                    <v-btn
                      href="#content"
                      color="thirdley"
                      class="ml-3"
                      tile
                      outlined
                    >
                      Details&nbsp;
                      <v-icon>{{ svgArrow }}</v-icon>
                    </v-btn>
                  </v-row>
                  <v-overlay
                    absolute
                    :value="ShowMenuClicked"
                  >
                    <v-row>
                      <v-col cols="10">
                        <iframe
                          v-if="ShowMenuClicked"
                          src="/static/lagrottomenu.pdf"
                          :height="[
                            $vuetify.breakpoint.smAndUp ? '600' : '400'
                          ]"
                          :width="[$vuetify.breakpoint.smAndUp ? '400' : '250']"
                        />
                      </v-col>
                      <v-col cols="2">
                        <v-btn
                          icon
                          @click="ShowMenuClicked = false"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-overlay>
                </div>
              </v-col>
            </v-slide-x-reverse-transition>
          </v-row>
        </v-container>
      </v-img>
    </v-sheet>
  </section>
</template>

<style scoped>
#scroll_more {
  position: absolute;
  bottom: 12%;
  left: 50%;
  width: 100%;
  height: 6px;
  cursor: pointer;
}
</style>

<script>
  import {
    mdiGlassWine,
    mdiCompass,
    mdiFruitGrapes,
    mdiFruitGrapesOutline,
    mdiBottleWine,
    mdiFileDownload,
    mdiArrowDownBold
  } from '@mdi/js'

  export default {
    name: 'HomeHero',
    props: {
      gradient: {
        type: String,
        default: 'to top, rgba(0,0,0,.01), rgba(0,0,0,.01)'
      }
    },
    data: () => ({
      ShowMenuClicked: false,
      svgWine: mdiGlassWine,
      svgCompass: mdiCompass,
      svgGrape: mdiFruitGrapes,
      svgGrapeOut: mdiFruitGrapesOutline,
      svgBottle: mdiBottleWine,
      svgDown: mdiFileDownload,
      svgArrow: mdiArrowDownBold,

      sbsocials: [
        ['https://www.facebook.com/SushiBoxSA', 'mdi-facebook', 'Facebook'],
        ['https://twitter.com/SushiBoxSA', 'mdi-twitter', 'Twitter'],
        ['https://www.instagram.com/sushiboxsa/', 'mdi-instagram', 'Instagram']
      ]
    })
  }
</script>
